const jQuery = require("jquery");

document.addEventListener("turbolinks:load", function (event) {
  let searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has("s")) {
    let share_code = searchParams.get("s");
    jQuery("a").each(function () {
      link_element = jQuery(this);
      let href = link_element.attr("href");
      if (href != undefined && !href.includes('s=') && (href == "/" || href.startsWith("/roles/") || href.startsWith("/#"))) {
        var new_href;
        if (href.startsWith("/#")) {
          new_href = href.replace("#", "?s=" + share_code + "#");
        } else {
          new_href = href + "?s=" + share_code;
        }

        link_element.attr("href", new_href);
      }
    });

    jQuery("img").each(function () {
      img_element = jQuery(this);
      let src = img_element.attr("src");
      if (src.endsWith("/image")) {
        let new_src = src + "?s=" + share_code;
        img_element.attr("src", new_src);
      }
    });
  }

  if (searchParams.has("k")) {
    let share_code = searchParams.get("k");
    jQuery("a").each(function () {
      link_element = jQuery(this);
      let href = link_element.attr("href");
      if (href == "/" || href.startsWith("/roles/")) {
        let new_href = href + "?k=" + share_code;
        link_element.attr("href", new_href);
      }
    });
  }
});
