const jQuery = require('jquery');

document.addEventListener('turbolinks:load', function(event) {
  if (typeof gtag === 'function') {
    gtag('config', '', {
      'page_path': event.data.url,
      'user_id': jQuery('body').data('ga-uid'),
      'custom_map': {'dimension1': 'User Role'},
      'User Role': jQuery('body').data('ga-role'),
    })
  }

  const tab_links = document.querySelectorAll('.tabs a');
  for (let link of tab_links) {
    link.addEventListener('click', function() {
      if (typeof gtag === 'function') {
        gtag('config', '', {
          'page_path': link.getAttribute('href'),
          'user_id': jQuery('body').data('ga-uid'),
          'custom_map': {'dimension1': 'User Role'},
          'User Role': jQuery('body').data('ga-role'),
        })
      }
    });
  };

  jQuery(document).on('click', 'a.track', function(e){
    if (typeof gtag === 'function') {
      var target = jQuery(e.target).closest('a');
      var href = target.attr('href');
      if (target.data('href')) {
        href = target.data('href');
      }
      gtag('config', '', {
        'page_path': href,
        'page_title': target.text().trim(),
        'user_id': jQuery('body').data('ga-uid'),
        'custom_map': {'dimension1': 'User Role'},
        'User Role': jQuery('body').data('ga-role'),
      });
    }
  });
});
