// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it"ll be compiled.

import { fromEvent } from "rxjs";
import { map, auditTime, filter, switchMap } from "rxjs/operators";

require("@rails/ujs").start();
require("turbolinks").start();

const jQuery = require("jquery");

const materialize = require("materialize-css");
global.materialize = materialize;

// Load Materalize
document.addEventListener("turbolinks:load", function () {
  materialize.AutoInit(document);

  Waves.displayEffect();

  var elems = document.querySelectorAll(".dropdown-trigger");
  materialize.Dropdown.init(elems, { coverTrigger: false });
});

// Fix Materalize sidenav
document.addEventListener("turbolinks:before-visit", function () {
  var elem = document.querySelector(".sidenav");
  var instance = materialize.Sidenav.getInstance(elem);
  if (instance) {
    instance.destroy();
  }
});

// Update addressbar on tab change
document.addEventListener("turbolinks:load", function () {
  var tab_links = document.querySelectorAll(".tabs a");
  for (let link of tab_links) {
    link.addEventListener("click", function () {
      Turbolinks.visit(link.getAttribute("href"));
    });
  }
});

// Autocomplete #Search
function getSearchResults(query) {
  if (!query) return Promise.resolve([]);
  const params = new URLSearchParams();
  const role_id = jQuery("body").data("current-role-id");
  params.append("query", query);
  params.append("role_id", role_id);
  return fetch(`/resources.json?${params.toString()}`)
    .then((r) => r.json())
    .then((r) => r.data);
}

const searchResultsToData = (results) =>
  results.reduce((map, result) => {
    map[result.title.trim()] = result.image;
    return map;
  }, {});

document.addEventListener("turbolinks:load", function () {
  document.querySelectorAll("#search .autocomplete").forEach((input) => {
    const instance = materialize.Autocomplete.init(input, {
      limit: 20,
      minLength: 3,
      sortFunction: function (a, b, inputString) {
        return 0;
      }, // Dont re-sort results
    });

    fromEvent(input, "input")
      .pipe(
        map((e) => e.target.value),
        filter((v) => v.length >= 2 || v.length === 0),
        auditTime(300),
        switchMap(getSearchResults)
      )
      .subscribe((results) => {
        instance.options.onAutocomplete = (title) => {
          title = title.trim();
          const match = results.find((r) => r.title.trim() === title);
          if (match) {
            Turbolinks.visit(match.url);
          }
        };
        instance.updateData(searchResultsToData(results));
        instance.open();
      });
  });

  jQuery("#search .input-field .prefix").on("click", function () {
    jQuery("input", jQuery(this).parent()).trigger("focus");
  });

  // Mobile
  jQuery(".search-trigger").on("click", function () {
    jQuery("#autocomplete-input-mobile").trigger("focus");
  });
});

import "!./application/google_analytics.js.erb";
import "!./application/share_links.js";
